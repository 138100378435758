
import { defineComponent } from "vue";

export default defineComponent({
  name: "GridItemLoader",
  props: {
    foldersView: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    const types = {
      gridItem: "heading, button",
      folderGridItem: "button, text",
    };

    return { types };
  },
});
