import { render, staticRenderFns } from "./GridItemLoader.vue?vue&type=template&id=329122c1&scoped=true"
import script from "./GridItemLoader.vue?vue&type=script&lang=ts"
export * from "./GridItemLoader.vue?vue&type=script&lang=ts"
import style0 from "./GridItemLoader.vue?vue&type=style&index=0&id=329122c1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "329122c1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VContainer,VRow,VSkeletonLoader})
